html,
body {
  font-size: 14px;
}

body {
  overflow-x: hidden;
}

.bg-body {
  background-color: #eae9e9;
}

[hidden] {
  display: none !important;
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 1280px;
  }
}

.page-title {
  font-weight: 300;
  letter-spacing: 2px;
}

/* File Input */
input[type="file"] {
  cursor: pointer;
}

input[type="file"]:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

input[type="file"]::-webkit-file-upload-button {
  color: #ffffff;
  background-color: var(--primary);
  border: 1px solid var(--primary);
  border-radius: 0.25rem;
  line-height: 1.5;
  cursor: pointer;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
}

input[type="file"]:disabled::-webkit-file-upload-button {
  cursor: not-allowed;
}

/* Canvas */
.canvas {
  display: block;
  max-width: 100%;
}

/* Inputs */
input[type="color"],
.settings-button {
  width: 30px;
  height: 30px;
  margin: 0 2px;
  padding: 0;
  border: 0;
  line-height: 1;
  cursor: pointer;
}

/* Buttons */
.btn:disabled {
  cursor: not-allowed;
}

.settings-button {
  background-image: url(../assets/icons/gear.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60%;
}

.btn-outline-secondary:hover svg path,
.btn-outline-dark:hover svg path {
  transition: fill 0.15s ease-in-out;
}

.btn-outline-secondary:hover svg path,
.btn-outline-dark:hover svg path {
  fill: #ffffff;
}

/* Modal */
.modal {
  background: rgba(0, 0, 0, 0.7);
}

/* Misc */
#inputsContainer {
  flex: 1;
}

#inputsContainer:empty {
  display: none;
}

#canvasPlaceholder {
  padding: 0.25rem;
  border: 2px dashed #c0c0c0;
}

#instructions {
  min-height: 200px;
  height: 100%;
}

.text-underline {
  text-decoration: underline;
}

.errorsContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1051;
  pointer-events: none;
}

.errorsContainer .alert {
  pointer-events: all;
}

details[open] summary {
  margin-bottom: 0.5rem;
}

/* capture-photo */
capture-photo:not(:defined) {
  display: none;
}

capture-photo {
  overflow: hidden;
}

capture-photo::part(video) {
  width: 100%;
  background-color: #000000;
}

capture-photo::part(actions-container) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem 0;
}

capture-photo::part(capture-button),
capture-photo::part(facing-mode-button) {
  text-indent: -9999px;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.30);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
}

capture-photo::part(capture-button) {
  width: 70px;
  height: 70px;
  background-image: url(../assets/icons/aperture.svg);
  background-size: 90%;
  background-color: #c2c3c7;
  border: 5px solid #ffffff;
}

capture-photo::part(facing-mode-button) {
  margin-right: calc(-50px - 2rem); /* facing mode button width + actions buttons gap */
  width: 50px;
  height: 50px;
  background-image: url(../assets/icons/camera-reverse.svg);
  background-size: 60%;
  background-color: #ffffff;
  border: 0;
}

capture-photo[loading]::part(video) {
  background-image: url(../assets/icons/spinner.svg);
  background-size: 60px;
  background-position: center center;
  background-repeat: no-repeat;
}

capture-photo[loading]::part(capture-button),
capture-photo[loading]::part(facing-mode-button) {
  opacity: 0.6;
  pointer-events: none;
}
